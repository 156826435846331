<template>
    <div>
        <!-- 這裡用 :style 動態設定 margin-left 或用其它方式都可以 -->
        <vs-radio class="w-full my-2" :class="!unit.isUnitMember ? 'text-grey' : ''" :style="{ marginLeft: depth * 20 + 'px' }" :disabled="!unit.isUnitMember" v-model="eid" :vs-value="unit.id">
            {{ unit.name }}
        </vs-radio>

        <!-- 如果有子單位，遞迴繼續 render -->
        <unit-tree-radio v-for="child in unit.children" :key="child.id" :unit="child" :depth="depth + 1" v-model="eid" />
    </div>
</template>

<script>
export default {
    name: 'UnitTreeRadio',
    props: {
        unit: {
            type: Object,
            required: true,
        },
        depth: {
            type: Number,
            default: 0,
        },
        // 父層傳下來的 v-model
        value: {
            type: [Number, null],
            default: null,
        },
    },
    computed: {
        // 讓子元件可以跟父元件共同綁定一個 "eid" 值
        eid: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val) // v-model 雙向綁定
            },
        },
    },
}
</script>
