<template>
    <div class="cd-body">
        <list :menu="menu" :total="total" :limit="limit" :isLoading="isLoading" :page.sync="page">
            <template v-slot:header-left>
                <span>文檔會診</span>
            </template>
            <template v-slot:header-right>
                <!-- 篩選 -->
                <div v-if="windowWidth <= 1024">
                    <vs-button class="cd-button-2w-icon rounded" type="flat" icon-pack="feather" icon="icon-filter" @click="switchFilter">{{ $t('apptMgmt.filter') }}</vs-button>
                </div>
            </template>
            <template v-slot:sidebar>
                <div class="mb-base">
                    <vs-input icon-no-border clearable icon="icon-search" icon-pack="feather" class="w-full input-rounded-full" v-model="query" />
                </div>
                <div class="mb-base">
                    <vs-radio class="w-full mb-3" v-model="status" vs-name="status" :vs-value="null">判讀狀態（不拘）</vs-radio>
                    <vs-radio class="w-full my-2 ml-4" v-model="status" vs-name="status" :vs-value="0">未判讀</vs-radio>
                    <vs-radio class="w-full my-2 ml-4" v-model="status" vs-name="status" :vs-value="1">已判讀</vs-radio>
                </div>
                <div class="mb-base">
                    <vs-radio class="w-full mb-3" v-model="isAssign" vs-name="isAssign" :vs-value="null">負責人（不拘）</vs-radio>
                    <vs-radio class="w-full my-2 ml-4" v-model="isAssign" vs-name="isAssign" :vs-value="1">我（{{ name }}）</vs-radio>
                    <vs-radio class="w-full my-2 ml-4" v-model="isAssign" vs-name="isAssign" :vs-value="0">未指派</vs-radio>
                </div>
                <div class="mb-base">
                    <vs-radio class="w-full mb-3" v-model="type" vs-name="type" :vs-value="null">檔案類型（不拘）</vs-radio>
                    <vs-radio class="w-full my-2 ml-4" v-for="dcType in dcTypes" :key="dcType.type" v-model="type" vs-name="type" :vs-value="dcType.type">{{ $common.getLangDB(dcType) }}</vs-radio>
                </div>
                <div class="mb-base" v-if="unit_list.length > 0">
                    <vs-radio class="w-full mb-3" v-model="eid" :vs-value="null">單位（不拘）</vs-radio>
                    <unit-tree-radio v-for="unit in treeUnits" :key="unit.id" :unit="unit" :depth="1" v-model="eid" />
                </div>
                <div class="mb-base">
                    <vs-checkbox v-model="includeChildren">含子單位</vs-checkbox>
                </div>
            </template>
            <template v-slot:content>
                <div class="mb-4 flex flex-wrap items-center gap-y-2 gap-x-4 text-lg" v-if="query || status != null || isAssign != null || type != null || eid">
                    <span>篩選條件：</span>
                    <vs-chip class="p-2 text-lg whitespace-no-wrap" closable @click="closeQueryFilter" v-if="query">
                        {{ query }}
                    </vs-chip>
                    <vs-chip class="p-2 text-lg whitespace-no-wrap" closable @click="closeStatusFilter" v-if="status != null">
                        {{ status > 0 ? '已判讀' : '未判讀' }}
                    </vs-chip>
                    <vs-chip class="p-2 text-lg whitespace-no-wrap" closable @click="closeIsAssignFilter" v-if="isAssign != null">
                        {{ isAssign > 0 ? '我（' + name + '）' : '未指派' }}
                    </vs-chip>
                    <vs-chip class="p-2 text-lg whitespace-no-wrap" closable @click="closeTypeFilter" v-if="type != null">
                        {{ type > 0 ? '我（' + name + '）' : '未指派' }}
                    </vs-chip>
                    <vs-chip class="p-2 text-lg whitespace-no-wrap" closable @click="closeEnterpriseFilter" v-if="eid">
                        {{ ename }}
                        <span class="text-sm">&nbsp;({{ includeChildren ? '含子單位' : '不含子單位' }})</span>
                    </vs-chip>
                </div>
                <div class="mb-4 flex flex-wrap items-center justify-between gap-4 text-lg">
                    <div class="flex flex-wrap items-center gap-4">
                        <span>共 {{ total }} {{ $t('membersMgmt.dataLength') }}</span>
                    </div>
                    <!-- <div class="flex flex-wrap items-center gap-4">
                        <span class="text-primary cursor-pointer hover:font-semibold whitespace-no-wrap">交辦</span>
                    </div> -->
                </div>
                <div class="mb-8 grid grid-cols-1 md:grid-cols-2 gap-4 items-stretch">
                    <OpinionItem :data="item" v-for="(item, index) in opinions" :key="index"></OpinionItem>
                </div>
            </template>
        </list>
    </div>
</template>
<script>
import { getOpinions } from '@/api/user'
import list from '@/components/Module/list.vue'
import OpinionItem from '@/components/Opinion/OpinionItem.vue'
import UnitTreeRadio from '@/components/Module/UnitTreeRadio.vue'
import { debounce } from 'lodash'
import { mapState } from 'vuex'
export default {
    components: {
        list,
        OpinionItem,
        UnitTreeRadio,
    },
    data() {
        return {
            opinions: [],
            dcTypes: [],
            query: '',
            total: 0,
            limit: 30,
            page: 1,
            isLoading: false,
            menu: false,
            status: 0,
            isAssign: null,
            type: null,
            eid: null,
            includeChildren: false,
        }
    },
    mounted() {
        // 預設值
        const savedUnit = localStorage.getItem('selectedUnit')
        this.eid = savedUnit ? JSON.parse(savedUnit) : null

        this.createBus()
        this.getData()
    },
    watch: {
        query: 'selectFilter',
        status: 'selectFilter',
        isAssign: 'selectFilter',
        eid(v) {
            localStorage.setItem('selectedUnit', JSON.stringify(v))
            this.selectFilter()
        },
        includeChildren: 'selectFilter',
        type: 'selectFilter',
    },
    computed: {
        ...mapState(['unit_list', 'partner_id', 'did', 'name', 'windowWidth']),
        filter() {
            const payload = {
                partner_id: this.partner_id,
                page: this.page,
                limit: this.limit,
                withDcTypes: 1,
                withEnterprise: 1,
                withToDoctor: 1,
                withFromDoctor: 1,
                withPatient: 1,
                withDoctorcare: 1,
                to_did: this.did,
                includeChildren: this.includeChildren,
            }
            if (this.eid) payload.eid = this.eid
            if (this.status != null) payload.status = this.status
            if (this.isAssign != null) payload.isAssign = this.isAssign
            if (this.type != null) payload.type = this.type
            if (this.query) payload.query = this.query
            return payload
        },
        ename() {
            const data = _.filter(this.unit_list, { id: this.eid })
            return data.length > 0 ? data[0].name : ''
        },
        treeUnits() {
            return this.$common.buildUnitTree()
        },
    },
    methods: {
        // 取文檔會診
        getData: debounce(async function (event) {
            this.isLoading = true
            const payload = this.filter
            const res = await getOpinions(payload)
            this.opinions = res.data.data.data
            this.dcTypes = res.data.data.dcTypes
            this.total = res.data.data.total
            this.isLoading = false
            window.scrollTo(0, 0)
        }, 300),
        // 開關篩選sidebar (手機版)
        switchFilter() {
            this.menu = !this.menu
        },
        selectFilter() {
            this.page = 1
            this.getData()
        },
        closeQueryFilter() {
            this.query = ''
            this.selectFilter()
        },
        closeStatusFilter() {
            this.status = null
            this.selectFilter()
        },
        closeIsAssignFilter() {
            this.isAssign = null
            this.selectFilter()
        },
        closeTypeFilter() {
            this.type = null
            this.selectFilter()
        },
        closeEnterpriseFilter() {
            this.eid = null
            this.selectFilter()
        },
        createBus() {
            this.$bus.$on('changePage', (page) => {
                this.page = page
                this.getData()
            })
            this.$bus.$on('finishInterp', (opinion_id) => {
                const index = _.findIndex(this.opinions, { id: opinion_id })
                this.opinions[index].status = 1
            })
        },
    },
    beforeDestroy() {
        this.$bus.$off('changePage')
        this.$bus.$off('finishInterp')
    },
}
</script>
