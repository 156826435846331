<template>
    <div class="flex flex-col p-4 border rounded shadow" style="border: #999 1px solid" :style="data.status == 0 ? 'background-color: #fee;' : 'background-color: white;'" v-if="data.doctorcare">
        <div class="h-full">
            <div class="mb-2 overflow-hidden text-xl flex justify-between items-center">
                <div class="flex items-center space-x-4 whitespace-no-wrap">
                    <span>{{ $common.getGender(data.patient.sex) }}性 {{ $common.getAge(data.patient.birthday) }}歲 {{ data.doctorcare.dctype.name }}</span>
                </div>
                <div class="flex items-center space-x-4 whitespace-no-wrap">
                    <vs-button class="cd-button-2w rounded" icon-pack="feather" icon="icon-image" v-if="data.status == 0" @click="interp">判讀</vs-button>
                    <span class="text-primary cursor-pointer hover:font-semibold" v-else @click="interp">已判讀</span>
                </div>
            </div>
            <div class="mb-1">
                <span v-if="data.from_did > 0">{{ data.from_doctor.dname }}: {{ data.message }}</span>
                <span v-else>系統發送</span>
            </div>
            <div class="cd-text-muted">
                <span>{{ data.created_at }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    methods: {
        interp() {
            const payload = { opinion_id: this.data.id }
            this.$bus.$emit('interp', payload)
        },
    },
}
</script>
